import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppConstants } from '../settings/appconstants';
import { TenantUserService } from './tenantuser.service';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private _whiteListApi: any = [
    AppConstants.REGISTRATION_API,
    AppConstants.FORGOT_PASSWORD_API,
    // AppConstants.AUTH_TOKEN_API,
    AppConstants.PING_API,
    AppConstants.PUBLIC_FITNESSCLASS_API,
    AppConstants.PUBLIC_SCHEDULE_MORE_INFO_API,
    AppConstants.UNLAYER_TEMPLATE_API,
    AppConstants.PUBLIC_FEATURED_EVENTS,
  ];

  private PUBLIC_API = '/public/';
  private LOGIN_API = '/login/';
  private REST_API = '/rest/';
  private CUSTOM_FORM = '/cform/';
  private EDCOURSE_API = '/edcourse/';
  private REGISTRATION_API = '/registration/';
  private STUDENTREG_API = '/studentreg/';
  private CLAN_API = '/clan';
  private SITE_API = '/site';
  private EDTEST_API = '/edtest/';
  private EDMEDIA_API = '/edmedia/';
  private CLINET_API = '/client/';
  private EDASSESMENT_API = '/edassesment/';
  private UNTEMPLATE = '/untemplate';
  private STEMPLATE = '/stemplate';
  private ESEND = '/esend';
  private TEMPLATE_PAGE = '/page';
  private TEMPLATE_PROGRAM = '/program';
  private SAPI_API = '/sapi/';
  private ITEM_SERVICE = '/item/';
  private ITEM_REPORT_SERVICE = '/ireport/';
  private NOTIFY_API = '/notification/';
  private SMS_API = '/sms/';
  private CAPPAIGN = '/campaign/';
  private PLUGIN = '/plugin/';
  private FAUTH = '/fauth/';
  private SNODE = '/snode/';
  private AUTH_MGR = '/authmgr/';
  private PROVIDER_SERVICE = '/providerservice/';
  private LLM = '/llm/';

  constructor(
    private _tenantUserService: TenantUserService,
    public toastr: ToastrService,
  ) {}

  /*
     intercept the request, stuff it with authToken or withCredentials flag based on
     if its a authToken mode or cookie mode
     For public urls do not have any authentication
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqWithAuth = this.getModifiedRequest(request);
    return next.handle(reqWithAuth).pipe(
      map(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse && this.isReinitInfo(request.url)) {
            // if (this._tenantUserService.getIsFranchiseLoginMode() == null) {
            //   this._tenantUserService.clearAuthToken();
            // }
          }
          return event;
        },
        // Operation failed; error is an HttpErrorResponse
        catchError((errorResp: any) => {
          console.log('Error HttpHandler', errorResp.error);
          this.toastr.error(errorResp.error);
          return throwError(errorResp.error);
        }),
      ),
    );
  }

  // private isCookieMode() {
  //   return !this._tenantUserService.isAuthTokenDomain();
  // }

  private getModifiedRequest(request: HttpRequest<any>): HttpRequest<any> {
    // const isWithCredVal: boolean = this.cookieWithCredVal(request.url);
    const token = this.getAuthorizationTokenIfValid(request.url);
    const headers = this.createAuthHeader(token, request.url);
    // if (this.isCookieMode()) {
    //   return request.clone({ headers: headers, withCredentials: isWithCredVal, reportProgress: true });
    // } else {
    return request.clone({ headers: headers, reportProgress: true });
    // }
  }

  private cookieWithCredVal(url: string) {
    let isWithCredVal = true;
    if (this.isWhiteListApi(url)) {
      isWithCredVal = false;
    }

    if (isWithCredVal && this.isVendorUrl(url)) {
      isWithCredVal = false;
    }
    return isWithCredVal;
  }

  private getAuthorizationTokenIfValid(url: string): string {
    let token = null;
    if (this._tenantUserService.isStudentPreview()) {
      token = this._tenantUserService.getStudentToken();
    } else if (this.isReinitInfo(url)) {
      token = this._tenantUserService.getAuthToken();
    } else if (this.isStuffToken(url)) {
      token = this._tenantUserService.getAuthToken();
    }
    return token;
  }

  private isAuthUrl(url: string) {
    let isAuth = !url.includes(this.LOGIN_API);
    return isAuth;
  }

  private createAuthHeader(token: string, url: string): HttpHeaders {
    let headers = new HttpHeaders();
    if (token && token != undefined && token != 'undefined' && this.isAuthUrl(url)) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }
    if (!this.isVendorUrl(url)) {
      headers = headers.set('content-type', 'application/json');
    }
    return headers;
  }

  private getApiFromUrl(url: string): string {
    const api: string = url.split('?')[0].split('//')[1];
    return api.substr(api.indexOf('/') + 1);
  }

  private isWhiteListApi(url: string): boolean {
    const api = this.getApiFromUrl(url);
    return this._whiteListApi.find(val => api.startsWith(val));
  }

  private isStuffToken(url: string): boolean {
    // if (!this._tenantUserService.isAuthTokenDomain()) {
    //   return false;
    // }
    const api = this.getApiFromUrl(url);
    let isStuffToken = true;
    if (this.isWhiteListApi(url) || api.startsWith(AppConstants.PING_API) || api.startsWith(AppConstants.AUTH_TOKEN_API)) {
      isStuffToken = false;
    }

    if (isStuffToken && this.isVendorUrl(url)) {
      isStuffToken = false;
    }
    return isStuffToken;
  }

  private isVendorUrl(url): boolean {
    return (
      !url.includes(this.PUBLIC_API) &&
      !url.includes(this.REST_API) &&
      !url.includes(this.UNTEMPLATE) &&
      !url.includes(this.STEMPLATE) &&
      !url.includes(this.ESEND) &&
      !url.includes(this.TEMPLATE_PAGE) &&
      !url.includes(this.TEMPLATE_PROGRAM) &&
      !url.includes(this.CUSTOM_FORM) &&
      !url.includes(this.EDCOURSE_API) &&
      !url.includes(this.REGISTRATION_API) &&
      !url.includes(this.STUDENTREG_API) &&
      !url.includes(this.CLAN_API) &&
      !url.includes(this.SITE_API) &&
      !url.includes(this.EDTEST_API) &&
      !url.includes(this.EDMEDIA_API) &&
      !url.includes(this.CLINET_API) &&
      !url.includes(this.EDASSESMENT_API) &&
      !url.includes(this.SAPI_API) &&
      !url.includes(this.ITEM_SERVICE) &&
      !url.includes(this.ITEM_REPORT_SERVICE) &&
      !url.includes(this.NOTIFY_API) &&
      !url.includes(this.SMS_API) &&
      !url.includes(this.CAPPAIGN) &&
      !url.includes(this.FAUTH) &&
      !url.includes(this.SNODE) &&
      !url.includes(this.PLUGIN) &&
      !url.includes(this.AUTH_MGR) &&
      !url.includes(this.PROVIDER_SERVICE) &&
      !url.includes(this.LLM)
    );
  }

  private isReinitInfo(url: string): boolean {
    const api = this.getApiFromUrl(url);
    return api.startsWith(AppConstants.REINIT_INFO_API);
  }
}
