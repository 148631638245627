import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { TUser } from '../models/tenantuser';
import { AppConstants } from '../settings/appconstants';
import { LocalstorageService } from './applocalstorage.service';
import * as _ from 'lodash';
import { WindowRef } from './winref.service';
import moment from 'moment-timezone';
import { Router } from '@angular/router';
import { TenantService } from './tenant.service';
import { SelectTenantDlgComponent } from '../../main/shared/components/select-tenant-dlg/select-tenant-dlg.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'app/main/shared/components/confirmation-dialog/confirmation-dialog.component';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class TenantUserService {
  constructor(
    private http: HttpClient,
    private _localStorageService: LocalstorageService,
    private _location: Location,
    private _windowRef: WindowRef,
    private _router: Router,
    private _tenantService: TenantService,
    public dialog: MatDialog,
    private _fuseNavigationService: FuseNavigationService,
  ) {
    this.instamojoRedirection();
    this.storeZoomUrl();
    this.storeStripeCode();
    // this.storeLoginCmd();
    this.storeAuthToken();
    this.storeZoomToken();
    this.checkSocialLoginToken();
    this.resetUser(false);
    this._configSubject = new BehaviorSubject(this._user);
    this._multipleTenantGoogleSubject = new BehaviorSubject(this._googleLoginOrgId);
    this._unsubscribeAll = new Subject();
  }

  get user(): any | Observable<any> {
    return this._configSubject.asObservable();
  }
  get googleLoginOrgId(): any | Observable<any> {
    return this._multipleTenantGoogleSubject.asObservable();
  }

  static AUTH_TYPE_EMAIL = 'email';
  static AUTH_TYPE_GRN = 'GRN';
  private _configSubject: BehaviorSubject<any>;
  private _multipleTenantGoogleSubject: BehaviorSubject<any>;
  private _user: TUser = new TUser();
  private _orgId: string;
  public availableTenantList: string[];
  // private _isAuthTokenDomain: boolean;
  private _unsubscribeAll: Subject<any>;
  private _tempSocialToken: string;
  private _googleLoginOrgId: string;
  private isInternalHomePageAvailable = false;
  private isExternalHomePageAvailable = false;
  private externalHomePageLink: string = undefined;
  tokenExpiryTime: number = -1;

  static isStaff(user: TUser): boolean {
    return _.isEqual(user.role, AppConstants.ROLE_ADMIN) || _.isEqual(user.role, AppConstants.ROLE_STAFF) || _.isEqual(user.role, AppConstants.ROLE_FRONTDESK);
  }

  static isStudent(user: TUser): boolean {
    return _.isEqual(user.role, AppConstants.ROLE_STUDENT);
  }

  static isStudentPreview(user: TUser): boolean {
    return user.isStudentPreview;
  }

  static displayRangeTime(stEpoch: any, edEpoch: any): any {
    const st = moment.unix(stEpoch).tz('UTC').format('h:mma');
    const ed = moment.unix(edEpoch).tz('UTC').format('h:mma');
    return [st, ed].join('-');
  }

  static displayEventStartTime(stEpoch: any): any {
    const utcMoment = moment.unix(stEpoch).tz('UTC');
    return utcMoment.format('ddd MMM-DD-YYYY hh:mm A');
  }

  setOrgId(orgId): void {
    this._orgId = orgId;
  }
  getOrgId(): string {
    return this._orgId;
  }

  // setIsAuthTokenDomain(isTokenDomain): void {
  //   this._isAuthTokenDomain = isTokenDomain;
  // }

  // isAuthTokenDomain(): boolean {
  //   return this._isAuthTokenDomain;
  // }

  private doSocialLogin(resolve, reject): void {
    const token = this.getSocialLoginToken();
    this._tempSocialToken = token == null ? this._tempSocialToken : token;
    this.initAuthToken(this._tempSocialToken, AppConstants.AUTH0_LOGIN, null).subscribe(
      (result: any) => {
        if (result.orgList) {
          this.availableTenantList = result.orgList;
          const dialogRef = this.dialog.open(SelectTenantDlgComponent, {
            disableClose: true,
            width: '500px',
            height: '550px',
            data: { availableTenantList: this.availableTenantList },
            backdropClass: 'backdropBackground',
          });

          // tslint:disable-next-line:no-shadowed-variable
          dialogRef.afterClosed().subscribe(result => {
            if (result !== '') {
              this._orgId = result;
              this.doSocialLogin(resolve, reject);
            }
          });
          resolve('selectTenant');
        } else {
          this.setOrgId(result.tenant.orgId);
          this.storeOrgIdOnLocal(result.tenant.orgId);

          const user = this.convertDataToUser(result.contact, result.tenant.orgId);
          this._tenantService.populateTenant(result.tenant);
          this.setLoginTrace(true);
          this.clearSocialLoginToken();
          user.orgId = this._orgId;
          this.setUserRole(user.role);
          let notifyFlag = true;

          if (token == null) {
            notifyFlag = true;
          } else if (this._windowRef.getHostName().includes('localhost')) {
            notifyFlag = false;
          }
          this.setUser(user, notifyFlag);
          if (notifyFlag) {
            this._multipleTenantGoogleSubject.next(result.tenant.orgId);
          }

          resolve(this._user);
        }
      },
      error => {
        console.log('error', error);
        this.setLoginTrace(false);
        const message = 'Sorry, ' + error + '.' + 'Please check your email or register for a new account.';
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          panelClass: 'dlg-contct-div',
          disableClose: true,
          height: '190',
          width: '500px',
          data: {
            actionBtn: 'Confirm',
            dialogTitle: 'Account Not Found',
            content: message,
            isErrorDlg: true,
          },
        });

        dialogRef.afterClosed().subscribe((response: boolean) => {
          if (!response) {
          }
        });

        resolve('selectTenant');
      },
    );
  }

  initUser = isAuthValid =>
    new Promise((resolve, reject) => {
      // only do the get user call if the login traace is prsent
      if (this.isSocialLogin()) {
        this.doSocialLogin(resolve, reject);
      } else if (this.isLoginTracePresent()) {
        this.reinitInfo().subscribe((result: any) => {
          if (result.failed) {
            this.setLoginTrace(false);
            this.logoutAndResetuser();
            this.resetUser(true);
          } else {
            const user = this.convertDataToUser(result.contact, result.tenant.orgId);
            this.setUserRole(user.role);
            this._tenantService.populateTenant(result.tenant);
            this.setLoginTrace(true);

            this.setUser(user, true);
          }
          resolve(this._user);
        });
      } else {
        this.setLoginTrace(false);
        this.resetUser(true);
        resolve(this._user);
      }
    });

  // isValidCookie(isAuthValid: boolean): boolean {
  //   let isValid = true;
  //   if (!this.isAuthTokenDomain() && !isAuthValid) {
  //     const authToken = this.getAuthToken();
  //     if (!authToken) {
  //       isValid = false;
  //     }
  //   }
  //   return isValid;
  // }

  isStaffRole(): boolean {
    return TenantUserService.isStaff(this._user);
  }

  isStaffRoleStorage(): boolean {
    const userrole = this.getUserRole();
    if (userrole === undefined || userrole == null) {
      return true;
    }
    return _.isEqual(userrole, AppConstants.ROLE_ADMIN) || _.isEqual(userrole, AppConstants.ROLE_STAFF) || _.isEqual(userrole, AppConstants.ROLE_FRONTDESK);
  }

  isStudentRole(): boolean {
    return TenantUserService.isStudent(this._user);
  }

  isStudentRoleStorage(): boolean {
    const userrole = this.getUserRole();
    if (userrole === undefined || userrole == null) {
      return true;
    }
    return _.isEqual(userrole, AppConstants.ROLE_STUDENT);
  }

  isStudentPreview(): boolean {
    return TenantUserService.isStudentPreview(this._user);
  }

  isUserLoggedIn(): boolean {
    return this._user.isLoggedIn;
  }

  getuser(): TUser {
    return this._user;
  }

  setUser(user: TUser, notify: boolean = true): void {
    user.isStudentPreview = this._user.isStudentPreview;
    user.studentToken = this._user.studentToken;
    this._user = null;
    this._user = user;
    if (notify) {
      this._configSubject.next(this._user);
    }
  }

  get adminToken() {
    let token = this._localStorageService.getItem('admin_permanent_token');
    return token;
  }

  setPreviewMode(isPreview: boolean): void {
    if (this._user.isPreviewMode !== isPreview) {
      this._user.isPreviewMode = isPreview;
      this._configSubject.next(this._user);
    }
  }
  setPreviewModeOfSecuredPage(isPreview: boolean): void {
    if (this._user.isPreviewMode !== isPreview) {
      this._user.isPreviewMode = isPreview;
      this._user.isPreviewOfSecuredPage = isPreview;
      this._configSubject.next(this._user);
    }
  }

  setStudentUser(token: string): void {
    this._user.isStudentPreview = token != null;
    this._user.studentToken = token;
  }

  login(payload: any, isRememberPassword: boolean): Observable<any> {
    const url = `${environment.apiBaseUrl}/${AppConstants.LOGIN_API}`;

    const data = {
      tid: payload.tid,
      userId: payload.userId,
      password: payload.password,
    };

    return this.http.post(url, data).pipe(
      map((result: any) => {
        this.storeTokenDetails(result.access_token, result.refresh_token);
        this.storeRememberPassData(payload, isRememberPassword);
        this._tenantService.populateTenant(result.tenant);
        return this.convertDataToUser(result.contact, result.tenant.masterOrgId);
      }),
      catchError(err => {
        console.log('err', err);
        this.setLoginTrace(false);
        throw new Error('error occured by during login' + err);
      }),
    );
  }

  getRememberData(): string {
    const data = this._localStorageService.getItem(AppConstants.REMEMBER_PASS_DATA);
    return data == null ? '' : atob(data);
  }

  getOrgIdFromStorage(): string {
    return this._localStorageService.getItem(AppConstants.ORG_ID);
  }

  storeOrgIdOnLocal(orgId): void {
    this._localStorageService.setItem(AppConstants.ORG_ID, orgId);
  }
  private storeRememberPassData(payload: any, isRemember: boolean): void {
    if (isRemember) {
      const userId = payload.userId;
      const pass = payload.password;
      this._localStorageService.setItem(AppConstants.REMEMBER_PASS_DATA, btoa(userId + ';;' + pass));
    } else {
      this._localStorageService.removeItem(AppConstants.REMEMBER_PASS_DATA);
    }
  }

  register(payload: any): Observable<any> {
    const data = {
      orgId: this._orgId,
      password: payload.password,
      email: payload.email,
      name: payload.firstname,
      lastName: payload.lastname,
      franchiseId: payload.franchiseId === AppConstants.MASTER_FRANCHISE ? '' : payload.franchiseId,
    };
    const url = `${environment.apiBaseUrl}/public/user/registration`;
    return this.http.post(url, data).pipe(
      map(result => {
        return result;
      }),
    );
  }

  forgotPassword(payload: any): Observable<any> {
    const data = {
      orgId: payload.orgId ? payload.orgId : this._windowRef.getHostName(),
      email: payload.email,
      franchiseId: payload.franchiseId === AppConstants.MASTER_FRANCHISE ? '' : payload.franchiseId,
    };
    const url = `${environment.apiBaseUrl}/rest/user/forgotPassword`;
    return this.http.post(url, data);
  }

  private initAuthToken(token: string, type: string, email: any): Observable<any> {
    // let redirectUrl = "https://" + window.location.hostname;
    const redirectUrl = this._windowRef.getHostName();
    const payload = {
      orgId: this._orgId,
      accessToken: token,
      redirectUrl: redirectUrl,
      authType: type,
      franchiseId: this.getFranchiseId(),
    };
    const url = `${environment.apiBaseUrl}/public/authToken`;
    return this.http.post(url, payload).pipe(
      map((result: any) => {
        result.failed = false;
        this.storeTokenDetails(result.access_token, result.refresh_token);
        return result;
      }),
      catchError(err => {
        this.setLoginTrace(false);
        // throw new Error('error occured by during auth' + err.error.message);
        return throwError(err.error.message);
      }),
    );
  }

  /*
   fake login 
  */
  private getTestLogin(email: string): any {
    const expected = {
      access_token: 'sample token',
      contact: {
        // new fileds
        guId: 'Jr0XJqvdq9',
        role: 'ROLE_ADMIN',
        orgId: this._orgId,
        isLoggedIn: true,
        // existing fields
        balance: 0,
        children: null,
        description: null,
        email: email,
        fullName: 'Angular Test',
        hasAcceptedTerms: true,
        hasParent: false,
        imageUrl: null,
        isAdminVerified: true,
        isEmailVerified: false,
        isFirstLogin: false,
        isLocalPicture: 'false',
        isParent: false,
        lastName: 'Test',
        name: 'Angular',
        phone: '+1-4098999999',
        picture: 'https://lh3.googleusercontent.com/-i1q_aCDjTf4/AAAAAAAAAAI/AAAAAAAAAAA/MZA3-rTo01Y/photo.jpg',
      },
    };
    return expected;
  }

  logout(): Observable<any> {
    this.removeFranchiseLoginMode();
    this.removeFranchiseLoginGuId();
    const url = `${environment.apiBaseUrl}/app/logout`;
    // if (this.isAuthTokenDomain()) {
    //   this.logoutAndResetuser();
    //   return of(this._user);
    // } else {
    return this.http.post(url, {}).pipe(
      map(data => {
        this.logoutAndResetuser();
        return this._user;
      }),
      catchError(err => of(this.logoutAndResetuser())),
    );
    // }
  }

  logoutAndResetuser(): void {
    // if(this._windowRef.getHostName().includes("localhost")){
    //   this.clearOrgIdFromStorage()
    //   this._orgId = undefined;
    // }
    this.clearAuthToken();
    this.clearSocialLoginToken();
    this.clearUserRole();
    this.clearAuthRefreshToken();
    this.tokenExpiryTime = -1;
  }

  // Get the auth token
  getAuthToken(): string {
    return this._localStorageService.getItem(AppConstants.AUTHTOKEN_KEY);
  }

  getFranchiseId(): string {
    const franchiseId = this._localStorageService.getItem(AppConstants.FRANCHISE_LOGIN);
    this._localStorageService.removeItem(AppConstants.FRANCHISE_LOGIN);
    return franchiseId == null || franchiseId === AppConstants.MASTER_FRANCHISE ? '' : franchiseId;
  }

  getStudentToken(): string {
    return this._user.studentToken;
  }

  isInstamojo(): boolean {
    return this._localStorageService.getItem(AppConstants.INSTAMOJO_TYPE) ? true : false;
  }

  isZoomScreen(): boolean {
    return this._localStorageService.getItem(AppConstants.ZOOMURL_KEY) ? true : false;
  }

  isStripeScreen(): boolean {
    return this._localStorageService.getItem(AppConstants.STRIPE_KEY) ? true : false;
  }

  removeStripeKey(): void {
    this._localStorageService.removeItem(AppConstants.STRIPE_KEY);
  }

  removeStripeCode(): void {
    this._localStorageService.removeItem(AppConstants.STRIPE_CODE);
  }

  removeZoomKey(): void {
    this._localStorageService.removeItem(AppConstants.ZOOMURL_KEY);
  }

  removeZoomCode(): void {
    this._localStorageService.removeItem(AppConstants.ZOOMURL_CODE);
  }

  getZoomCode(): any {
    this._localStorageService.getItem(AppConstants.ZOOMURL_CODE);
  }

  removeInstamojoType(): void {
    this._localStorageService.removeItem(AppConstants.INSTAMOJO_TYPE);
  }

  isSocialLogin(): boolean {
    return this._localStorageService.getItem(AppConstants.SOCIAL_LOGIN) ? true : false;
  }

  isZoomLogin(): boolean {
    return this._localStorageService.getItem(AppConstants.ZOOMTOKEN_KEY) ? true : false;
  }

  clearZoomToken(): void {
    this._localStorageService.removeItem(AppConstants.ZOOMTOKEN_KEY);
  }

  getSocialLoginToken(): string {
    const token = this._localStorageService.getItem(AppConstants.SOCIAL_LOGIN);
    this._localStorageService.removeItem(AppConstants.SOCIAL_LOGIN);
    return token;
  }

  clearSocialLoginToken(): void {
    this._localStorageService.removeItem(AppConstants.SOCIAL_LOGIN);
  }

  public storeTokenDetails(access_token, refresh_token): void {
    const decodeAuth: any = jwt_decode(access_token);
    this.tokenExpiryTime = decodeAuth.exp;
    this.setAuthToken(access_token);
    this.setAuthRefreshToken(refresh_token);
  }

  private reinitInfo(): any {
    const isStudentPreview = this.isStudentPreview();
    const url = `${environment.apiBaseUrl}/rest/reInitInfo?preview=${isStudentPreview}`;
    return this.http.get(url).pipe(
      map((result: any) => {
        this.storeTokenDetails(result.access_token, result.refresh_token);
        return result;
      }),
      catchError(err => {
        // return of(this.resetUser(true));
        const result = { failed: true };
        return of(result);
        // this.resetUsers(true);
        // throw new Error('error occurred during reinit auth' + err);
      }),
    );
    // return this.http.get(url).pipe(
    //     tap(res => console.log('HTTP response:', res)),
    //     map(data => {
    //       // return this.convertDataToUser(data);
    //       return data;
    //     }),
    //     catchError(err => {
    //       this.setLoginTrace(false);
    //       // return of(this.resetUser(true));
    //       // this.resetUser(true);
    //       throw new Error('error occurred during reinit auth' + err);
    //     })
    // );
  }
  /*
   * Get the users info, if not user is logged in, then create a new User
   */
  // private getUserInfo(): Observable<any> {
  //   console.log('getuserinfo called');
  //   const url = `${environment.apiBaseUrl}/rest/user/info`;
  //   return this.http.get(url).pipe(
  //     tap(res => console.log('HTTP response:', res)),
  //     map(data => {
  //       return this.convertDataToUser(data);
  //     }),
  //     catchError(err => of(new TUser(this._orgId)))
  //   );
  // }

  getAuthTokenAPI(token): any {
    return this.http.get(`${environment.apiBaseUrl}/rest/authToken?token=${token}`);
  }

  contactSearch(search: any): Observable<any> {
    const url = `${environment.apiBaseUrl}/rest/contact/?autoComplete=true&q=${search}`;
    return this.http.get(url);
  }

  convertDataToUser(data, orgId: string): TUser {
    const tuser: TUser = new TUser();
    if (data) {
      tuser.guId = data.guId;
      tuser.name = data.name;
      tuser.fullName = data.fullName;
      tuser.lastName = data.lastName;
      tuser.email = data.email;
      tuser.phone = data.phone;
      tuser.picture = data.picture;
      tuser.role = data.role;
      tuser.orgId = orgId;
      tuser.isLoggedIn = true;
      tuser.isNewClient = data.isNewClient;
      tuser.isNewTenant = data.isNewTenant;
      tuser.hasAcceptedTerms = true; // data.hasAcceptedTerms;
      tuser.isFirstLogin = data.isFirstLogin;
      tuser.isEmailVerified = data.isEmailVerified;
      tuser.isAdminVerified = data.isAdminVerified;
    }
    return tuser;
  }

  public resetUser(notify: boolean): void {
    this.setUser(new TUser(this._orgId), notify);
  }

  setAuthToken(token: string): void {
    this._localStorageService.setItem(AppConstants.AUTHTOKEN_KEY, token);
  }
  setIsFranchiseLoginMode(mode: string): void {
    this._localStorageService.setItem(AppConstants.FRANCHISE_LOGIN_SWITCH, mode);
  }
  removeFranchiseLoginMode(): void {
    this._localStorageService.removeItem(AppConstants.FRANCHISE_LOGIN_SWITCH);
  }
  getIsFranchiseLoginMode(): string | null {
    return this._localStorageService.getItem(AppConstants.FRANCHISE_LOGIN_SWITCH);
  }

  setFranchiseLoginGuId(guId: string): void {
    this._localStorageService.setItem(AppConstants.FRANCHISE_LOGIN_SWITCH_USER, guId);
  }
  removeFranchiseLoginGuId(): void {
    this._localStorageService.removeItem(AppConstants.FRANCHISE_LOGIN_SWITCH_USER);
  }
  getFranchiseLoginGuId(): string | null {
    return this._localStorageService.getItem(AppConstants.FRANCHISE_LOGIN_SWITCH_USER);
  }

  setUserRole(role: string): void {
    this._localStorageService.setItem(AppConstants.USER_ROLE, role);
  }

  getUserRole(): string | null {
    return this._localStorageService.getItem(AppConstants.USER_ROLE);
  }

  clearAdminToken(): void {
    this._localStorageService.removeItem(AppConstants.ADMIN_TOKEN);
  }

  clearUserRole(): void {
    this._localStorageService.removeItem(AppConstants.USER_ROLE);
  }

  clearOrgIdFromStorage(): void {
    this._localStorageService.removeItem(AppConstants.ORG_ID);
  }

  clearAuthToken(): void {
    console.log('CLEAR AUTH TOKEN****************');
    this._localStorageService.removeItem(AppConstants.AUTHTOKEN_KEY);
    this.setLoginTrace(false);
  }

  setRegisterCategoryGuId(categoryGuId: string): void {
    this._localStorageService.setItem(AppConstants.REGISTER_CATEGORY_GUID, categoryGuId);
  }

  getRegisterCategoryGuId(): string {
    return this._localStorageService.getItem(AppConstants.REGISTER_CATEGORY_GUID);
  }

  removeRegisterCategoryGuId(): void {
    this._localStorageService.removeItem(AppConstants.REGISTER_CATEGORY_GUID);
  }

  setEventType(eventType: string): void {
    this._localStorageService.setItem(AppConstants.EVENT_TYPE, eventType);
  }

  getEventType(): string {
    return this._localStorageService.getItem(AppConstants.EVENT_TYPE);
  }

  removeEventType(): void {
    this._localStorageService.removeItem(AppConstants.EVENT_TYPE);
  }

  setEventParam(eventParam: any): void {
    this._localStorageService.setItem(AppConstants.EVENT_PARAM, JSON.stringify(eventParam));
  }

  getEventParam(): string {
    return this._localStorageService.getItem(AppConstants.EVENT_PARAM);
  }

  removeEventParam(): void {
    this._localStorageService.removeItem(AppConstants.EVENT_PARAM);
  }

  showLockLoginEvent(type: any, event: any, categoryGuId: any, tData: any): void {
    if (categoryGuId === undefined) {
      this.removeRegisterCategoryGuId();
    } else {
      this.setRegisterCategoryGuId(categoryGuId);
    }
    this.setEventType(type);

    if (event === undefined) {
      this.removeEventParam();
    } else {
      this.setEventParam(event);
    }
    this._router.navigate(['/login']);
  }

  /*
  store auth token direclty to localstorage and dont use localstorage service as 
  it might not have been initialized yet
  */
  private storeAuthToken(): void {
    const token = this.getQueryParameter(AppConstants.AUTHTOKEN_KEY);
    if (token != null) {
      this.setAuthToken(token);
      this.setLoginTrace(true);
    }
  }

  private storeZoomUrl(): void {
    const token = this.getQueryParameter(AppConstants.ZOOMURL_KEY);

    if (token != null) {
      this._localStorageService.setItem(AppConstants.ZOOMURL_KEY, token);
      this._localStorageService.setItem(AppConstants.ZOOMURL_CODE, token);
      // redirect to login, show login dialog
      this._router.navigate(['/login']);
    }
  }

  private storeStripeCode(): void {
    const param = this.getQueryParameter(AppConstants.STRIPE_KEY);
    const stripeCode = this.getQueryParameter('code');
    if (param != null) {
      this._localStorageService.setItem(AppConstants.STRIPE_KEY, 'stripe');
      this._localStorageService.setItem(AppConstants.STRIPE_CODE, stripeCode);
      // redirect to login, show login dialog
      /// this._router.navigate(["/login"]);
    }
  }

  private storeZoomToken(): void {
    const token = this.getQueryParameter(AppConstants.ZOOMTOKEN_KEY);
    if (token != null) {
      this._localStorageService.setItem(AppConstants.ZOOMTOKEN_KEY, token);
    }
  }

  private extractUrlParams(locationStr: string): any {
    return locationStr.split('#')[1];
  }

  private decodeParams(name: any): any {
    const results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (results == null) {
      return null;
    } else {
      return decodeURI(results[1]) || 0;
    }
  }

  private findUrlValue(urlParams, key): any {
    const kvPairs = urlParams.split('&');
    // tslint:disable-next-line:only-arrow-functions typedef
    return kvPairs.reduce(function (all, item, index) {
      if (item.startsWith(key)) {
        const aPair = item.split('=');
        all = aPair[1];
      }
      return all;
    }, null);
  }

  private getLoginToken(): any {
    const locationStr = this._location.path(true);
    const argsVal = this.extractUrlParams(locationStr);
    let token;
    if (argsVal) {
      token = this.findUrlValue(argsVal, AppConstants.SOCIAL_LOGIN);
    }
    return token;
  }

  private instamojoRedirection(): void {
    // console.log('this._location.path()', this._location.path());
    if (/paymentaction=/.test(this._location.path())) {
      this._localStorageService.setItem(AppConstants.INSTAMOJO_TYPE, AppConstants.INSTAMOJO_VALUE);
      const paymentAction = this.decodeParams('paymentaction');
      const paymentId = this.decodeParams('payment_id');
      if (paymentAction === 'public') {
        this._localStorageService.setItem('public_payment_id', '' + paymentId);
      } else {
        this._localStorageService.setItem('payment_id', '' + paymentId);
      }
    }
  }
  private checkSocialLoginToken(): void {
    const token = this.getLoginToken();
    if (token != null) {
      this._localStorageService.setItem(AppConstants.SOCIAL_LOGIN, token);
    }
  }

  private getQueryParameter(key: string): string {
    const parameters = new URLSearchParams(this._location.path());
    return parameters.get(key);
  }

  setLoginTrace(isPresent: boolean): void {
    const val = String(isPresent);
    this._localStorageService.setItem(AppConstants.LOGIN_TRACE, val);
  }

  private isLoginTracePresent(): boolean {
    let isPresent = false;
    const trace: string = this._localStorageService.getItem(AppConstants.LOGIN_TRACE);
    if (trace === 'true') {
      isPresent = true;
    }
    return isPresent;
  }

  getAdminList(): Observable<any> {
    const url = `${environment.apiBaseUrl}/rest/contact?userType=admin`;

    return this.http.get(url).pipe(
      map(result => {
        return result;
      }),
    );
  }

  getTenantSettingMenus(orgId): any {
    return this.http.get(`${environment.apiBaseUrl}/edtest/tenant/settings/menu?name=${orgId}`);
  }
  isInternalHomeAvailable(): any {
    return this.isInternalHomePageAvailable;
  }

  public isHomePagePresent(webPageList: any[]): boolean {
    let isHomePresent = false;
    const homePage = webPageList.find(page => page.name === AppConstants.HOME_PAGE);
    if (homePage) {
      if (homePage.isExternalLink) {
        isHomePresent = false;
      } else {
        isHomePresent = true;
      }
    }
    return isHomePresent;
  }

  isEventsPagePresent(webPageList: any[]): boolean {
    return webPageList.some(page => page.url === AppConstants.EVENTS_PAGE);
  }

  setIsInternalHomeAvailable(stat): void {
    this.isInternalHomePageAvailable = stat;
  }
  isExternalHomeAvailable(): boolean {
    return this.isExternalHomePageAvailable;
  }
  setIsExternalHomeAvailable(stat): void {
    this.isExternalHomePageAvailable = stat;
  }
  setExternalHomeLink(link): void {
    this.externalHomePageLink = link;
  }
  getExternalHomeLink(): string {
    return this.externalHomePageLink;
  }
  getAccessTokenFromRefresh(): Observable<any> {
    const url = `${environment.apiBaseUrl}/${AppConstants.REFRESH_ACCESS_TOKEN_API}`;
    const data = {
      token: this.getAuthRefreshToken(),
    };

    return this.http.post(url, data).pipe(
      map((result: any) => {
        this.storeTokenDetails(result.access_token, result.refresh_token);
        return result;
      }),
      catchError(error => {
        this.setLoginTrace(false);
        this.logoutAndResetuser();
        this.resetUser(true);
        console.log('refresh token also expired, Logout');
        return throwError('Error');
      }),
    );
  }
  clearAuthRefreshToken(): void {
    this._localStorageService.removeItem(AppConstants.AUTH_REFRESH_TOKEN__KEY);
  }
  clearAuthTokenExpiry(): void {
    this._localStorageService.removeItem(AppConstants.AUTH_TOKEN_EXPIRATION_KEY);
  }
  setAuthRefreshToken(refreshToken: string): void {
    this._localStorageService.setItem(AppConstants.AUTH_REFRESH_TOKEN__KEY, refreshToken);
  }
  getAuthRefreshToken(): string {
    return this._localStorageService.getItem(AppConstants.AUTH_REFRESH_TOKEN__KEY);
  }

  isAccessTokenAboutToExpire(): boolean {
    if (this.tokenExpiryTime < 0) {
      return true;
    } else {
      const tokenRefreshBufferTime = +`${environment.tokenRefreshBufferTimeInMinutes}` * 1000 * 60;
      const tokenExpiryDateTime: any = this.tokenExpiryTime * 1000;
      return tokenExpiryDateTime - tokenRefreshBufferTime <= Date.now();
    }
  }
  isAccessTokenExpired(): boolean {
    if (this.tokenExpiryTime < 0) {
      return true;
    } else {
      const tokenExpiryDateTime: any = this.tokenExpiryTime * 1000;
      return tokenExpiryDateTime <= Date.now();
    }
  }
}
