import { Component, OnInit } from '@angular/core';
import { ReportsService } from 'app/main/admin/reports/reports.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { ViewChild } from '@angular/core';
import moment from 'moment-timezone';
import { fuseAnimations } from '@fuse/animations';
import { FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppSettingsService } from 'app/main/admin/app-settings/services/app-settings.service';
import { SubSink } from 'subsink';
import { PlanService } from './plan.service';
import { ToastrService } from 'ngx-toastr';
import { AppUtilsService } from 'app/core/services/apputils.service';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss'],
  animations: fuseAnimations,
})
export class PlanComponent implements OnInit {
  subs = new SubSink();
  totalRecords: number;
  pageIndex: number;
  select: {};
  start: number = 0;
  pageSize: number = 50;
  pageEvent: PageEvent;
  dataSource: any;
  data: any;
  displayedColumns: string[] = ['client', 'invoice', 'price', 'status', 'created_date'];
  pageSizeOptions: any[] = [50, 100, 150];
  tableSearch = new FormControl('');
  isLoading: boolean = false;
  _tenantConfig: any;
  subscription: any = {};
  year: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private planService: PlanService,
    private toastr: ToastrService,
    public appSettingsService: AppSettingsService,
  ) {}

  ngOnInit(): void {
    this.year = AppUtilsService.currentYear();
    this.subs.add(
      this.appSettingsService.getAccountDetail().subscribe(data => {
        this._tenantConfig = data;
      }),
    );
    this.getPlan();
    this.getSubsriptions(null);
  }
  upgrade() {
    this.router.navigate(['/settings/plan/upgrade'], {
      relativeTo: this.route,
    });
  }

  getPlan() {
    this.subs.add(
      this.planService.getPlan().subscribe(
        result => {
          this.subscription = result;
          this.subscription.planName = this.subscription.planName == null ? 'Free Plan' : this.subscription.planName.replace(/_/g, ' ');
        },
        error => {
          console.log(error);
          //this.toastr.error(error.message);
        },
      ),
    );
  }
  getSubsriptions(event: PageEvent) {
    this.isLoading = true;
    console.log(event);
    if (event != null) {
      this.start = event.pageIndex * event.pageSize;
      this.pageSize = event.pageSize;
      this.pageIndex = event.pageIndex;
    }
    this.subs.add(
      this.planService.getSubscriptions(this.year, this.start, this.pageSize, '').subscribe(
        (result: any) => {
          this.data = result.data;
          this.totalRecords = result.recordsTotal;
          this.dataSource = new MatTableDataSource<any>(this.data);
          this.isLoading = false;
        },
        error => {
          console.log(error);
          //this.toastr.error(error.message);
        },
      ),
    );
  }

  public contactView(guId): any {
    this.router.navigate(['/contacts/' + guId + '/details'], { relativeTo: this.route });
  }
}
