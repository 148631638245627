import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";
import { Observable, BehaviorSubject } from "rxjs";
import { map, catchError, tap, flatMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class UnlayerService {
  constructor(private http: HttpClient) {}

  unlayertemplate: string = "unlayertemplate";
  untemplate: string = "stemplate";

  loadAllCustomTemplates(ttype): Observable<any> {
    const apiUrl = `${environment.apiBaseUrl}/${this.unlayertemplate}/untemplates?orgId=master&type=${ttype}`;
    return this.http.get(apiUrl, { withCredentials: false }).pipe(
      tap((res) => console.log("HTTP response:", res)),
      map((data) => {
        return data;
      })
    );
  }

  getCategoryTemplate(guId, name): Observable<any> {
    const apiUrl = `${environment.apiBaseUrl}/snode/pcourse/${guId}/${name}`;
    return this.http.get(apiUrl, { withCredentials: false }).pipe(
      tap((res) => console.log("HTTP response:", res)),
      map((data) => {
        return data;
      })
    );
  }

  getCategoryTemplateData(guId, name?): Observable<any> {
    const apiUrl = `${environment.apiBaseUrl}/${this.untemplate}/page/guId/${guId}`;
    return this.http.get(apiUrl).pipe(
      tap((res) => console.log("HTTP response:", res)),
      map((data) => {
        return data;
      })
    );
  }

  getProgramTemplate(guId): Observable<any> {
    const apiUrl = `${environment.apiBaseUrl}/${this.untemplate}/program/guId/${guId}`;
    return this.http.get(apiUrl, { withCredentials: false }).pipe(
      tap((res) => console.log("HTTP response:", res)),
      map((data) => {
        return data;
      })
    );
  }

  getProgramTemplateNyName(guId, name): Observable<any> {
    const apiUrl = `${environment.apiBaseUrl}/${this.untemplate}/public/program/${guId}`;
    return this.http.get(apiUrl, { withCredentials: false }).pipe(
      tap((res) => console.log("HTTP response:", res)),
      map((data) => {
        return data;
      })
    );
  }

  addProgram(form: any) {
    return this.http.post(`${environment.apiBaseUrl}/${this.untemplate}/program`, form);
  }
  updateProgram(form: any, guId: any) {
    return this.http.put(`${environment.apiBaseUrl}/${this.untemplate}/program/${guId}`, form);
  }

  addUntemplate(form: any) {
    let url = `${environment.apiBaseUrl}/stemplate/untemplate`;
    return this.http.post(url, form);
  }

  saveTemplate(payload): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/${this.unlayertemplate}/untemplates`;
    return this.http.post(apiUrl, payload, { withCredentials: false }).pipe(
      tap((res) => console.log("HTTP response:", res)),
      map((data) => {
        return data;
      })
    );
  }

  saveWidgetTemplate(payload): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/${this.unlayertemplate}/widgets`;
    return this.http.post(apiUrl, payload, { withCredentials: false }).pipe(
      tap((res) => console.log("HTTP response:", res)),
      map((data) => {
        return data;
      })
    );
  }

  updateWidgetTemplate(id, payload): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/${this.unlayertemplate}/widgets/${id}`;
    return this.http.put(apiUrl, payload, { withCredentials: false }).pipe(
      tap((res) => console.log("HTTP response:", res)),
      map((data) => {
        return data;
      })
    );
  }

  getDefaultWidgetTemplate(ttype): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/${this.unlayertemplate}/widgets?default=true&orgid=master&type=${ttype}`;
    return this.http.get(apiUrl, { withCredentials: false }).pipe(
      tap((res) => console.log("HTTP response:", res)),
      map((data) => {
        return data;
      })
    );
  }
  getDefaultEmailTemplate(ttype): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/${this.unlayertemplate}/programemails?default=true&orgid=master&type=${ttype}`;
    return this.http.get(apiUrl, { withCredentials: false }).pipe(
      tap((res) => console.log("HTTP response:", res)),
      map((data) => {
        return data;
      })
    );
  }
  deleteWebPages(id): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/${this.unlayertemplate}/webpages/${id}`;
    return this.http.delete(apiUrl, { withCredentials: false }).pipe(
      tap((res) => console.log("HTTP response:", res)),
      map((data) => {
        return data;
      })
    );
  }

  getWidgetTemplate(guId): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/${this.unlayertemplate}/widgets?guid=${guId}`;
    return this.http.get(apiUrl, { withCredentials: false }).pipe(
      tap((res) => console.log("HTTP response:", res)),
      map((data) => {
        return data;
      })
    );
  }
  saveCategoryTemplate(payload): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/${this.unlayertemplate}/categories`;
    return this.http.post(apiUrl, payload, { withCredentials: false }).pipe(
      tap((res) => console.log("HTTP response:", res)),
      map((data) => {
        return data;
      })
    );
  }

  updateCategoryTemplate(id, payload): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/${this.unlayertemplate}/categories/${id}`;
    return this.http.put(apiUrl, payload, { withCredentials: false }).pipe(
      tap((res) => console.log("HTTP response:", res)),
      map((data) => {
        return data;
      })
    );
  }

  updateTemplate(id, payload): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/${this.unlayertemplate}/untemplates/${id}`;
    return this.http.put(apiUrl, payload, { withCredentials: false }).pipe(
      tap((res) => console.log("HTTP response:", res)),
      map((data) => {
        return data;
      })
    );
  }
  getDefaultTemplate(ttype): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/${this.unlayertemplate}/webpages?default=true&orgId=master&type="+ ${ttype}`;
    return this.http.get(apiUrl, { withCredentials: false }).pipe(
      tap((res) => console.log("HTTP response:", res)),
      map((data) => {
        return data;
      })
    );
  }

  getDefaultCategoryTemplate(ttype): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/${this.unlayertemplate}/categories?default=true&orgid=master&type=${ttype}`;
    return this.http.get(apiUrl, { withCredentials: false }).pipe(
      tap((res) => console.log("HTTP response:", res)),
      map((data) => {
        return data;
      })
    );
  }

  saveWebsiteTemplate(payload): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/${this.unlayertemplate}/webpages`;
    return this.http.post(apiUrl, payload, { withCredentials: false }).pipe(
      tap((res) => console.log("HTTP response:", res)),
      map((data) => {
        return data;
      })
    );
  }

  updateWebsiteTemplate(id, payload): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/${this.unlayertemplate}/webpages/${id}`;
    return this.http.put(apiUrl, payload, { withCredentials: false }).pipe(
      tap((res) => console.log("HTTP response:", res)),
      map((data) => {
        return data;
      })
    );
  }

  getWebsiteTemplates(orgId): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/${this.unlayertemplate}/webpages?orgId=${orgId}`;
    return this.http.get(apiUrl, { withCredentials: false }).pipe(
      tap((res) => console.log("HTTP response:", res)),
      map((data) => {
        return data;
      })
    );
  }
  
  getWebPage(id): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/${this.unlayertemplate}/webpages/${id}`;
    return this.http.get(apiUrl, { withCredentials: false }).pipe(
      tap((res) => console.log("HTTP response:", res)),
      map((data) => {
        return data;
      })
    );
  }

  getWebPagesByType(orgId, type): Observable<any> {
    let apiUrl = `${environment.apiBaseUrl}/${this.unlayertemplate}/webpages?&orgId=${orgId}&type=${type}`;
    return this.http.get(apiUrl, { withCredentials: false }).pipe(
      tap((res) => console.log("HTTP response:", res)),
      map((data) => {
        return data;
      })
    );
  }
}
