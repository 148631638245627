import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServicesService } from 'app/main/admin/services/services.service';
import { ProgramsService } from 'app/main/admin/programs/programs.service';
import { SubSink } from 'subsink';
import { TenantPing } from 'app/core/models/tenantPing';
import moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'app/core/settings/appconstants';
import { AppSettingsService } from 'app/main/admin/app-settings/services/app-settings.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppLoadService } from 'app/core/services/appload.service';
import { AppUtilsService } from 'app/core/services/apputils.service';
import { AppCourseCreateComponent } from 'app/main/shared/components/app-course-create/app-course-create.component';
import { fuseAnimations } from '@fuse/animations';
import { AddContactDlgComponent } from 'app/main/shared/components/add-contact-dlg/add-contact-dlg.component';
import { ContactsService } from 'app/main/admin/contacts/contacts.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ConnectEmailService } from '../connect-email.service';

@Component({
  selector: 'app-app-program-create',
  templateUrl: './connect-email.component.html',
  styleUrls: ['./connect-email.component.scss'],
  animations: fuseAnimations,
})
export class ConnectEmailComponent implements OnInit, OnDestroy {
  subs = new SubSink();
  emailForm: FormGroup;
  isLoading: boolean = false;
  usedEmail: string = '';
  loadSection: string = 'information'; // information | submitEmail | emailIdentified | emailIdentifiedAndConfirmed
  identifiedEmailProviderDetails: any = undefined;
  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
    private _connectEmailService: ConnectEmailService,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.emailForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      isShareAccount: ['', []],
    });
  }
  submitEmail() {
    this.isLoading = true;
    this.usedEmail = this.emailForm.get('email').value;
    const isSharedAccount = this.emailForm.get('isShareAccount').value;

    this.subs.add(
      this._connectEmailService.submitEmailForConnectEmailAPI(this.usedEmail).subscribe(
        (result: any) => {
          debugger;
          switch (result.provider) {
            case 'googlemail': {
              this.identifiedEmailProviderDetails = { providerName: 'googlemail', providerLabel: 'Google', appLabel: 'Gmail', logo: 'google.com', isShareAccount: isSharedAccount };
              this.loadSection = 'emailIdentified';
              break;
            }
            case 'zohomail': {
              this.identifiedEmailProviderDetails = { providerName: 'zohomail', providerLabel: 'Zoho', appLabel: 'Zoho App', logo: 'google.com', isShareAccount: isSharedAccount };
              this.loadSection = 'emailIdentified';

              break;
            }
            case 'outlookmail': {
              this.identifiedEmailProviderDetails = { providerName: 'outlookmail', providerLabel: 'Microsoft', appLabel: 'Outlook 365', logo: 'google.com', isShareAccount: isSharedAccount };
              this.loadSection = 'emailIdentified';

              break;
            }
            default: {
              this.identifiedEmailProviderDetails = { providerName: 'other', providerLabel: 'Other', appLabel: 'Others', logo: 'google.com', isShareAccount: isSharedAccount };
              break;
            }
          }
          this.isLoading = false;
        },
        error => {},
      ),
    );
  }

  confirmConnectEmail() {
    this.subs.add(
      this._connectEmailService.confirmConnectEmailAPI(this.identifiedEmailProviderDetails.providerName).subscribe(
        (result: any) => {
          window.open(result, '_self');
        },
        error => {},
      ),
    );
  }
  handleConnectToMailBtnClick() {
    this.loadSection = 'emailIdentifiedAndConfirmed';
    this.identifiedEmailProviderDetails.appLabel;
  }

  resetEmailForm() {
    this.loadSection = 'submitEmail';
    this.identifiedEmailProviderDetails = undefined;
    this.emailForm.reset();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
