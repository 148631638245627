import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { AppLoadService } from "app/core/services/appload.service";
import { environment } from "environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, map, take, toArray } from "rxjs/operators";
import { Product } from "../model/product";

@Injectable({
  providedIn: "root"
})
export class ProductService implements Resolve<any> {
  products: Product[] = [];
  public Product: Observable<any>;
  public role: Observable<any>;

  onProductsChanged: BehaviorSubject<any>;
  private _configProducts: BehaviorSubject<any>;
  private _configProduct: BehaviorSubject<any>;
  private _configRole: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient, private _appLoadService: AppLoadService) {
    // Set the defaults
    this.onProductsChanged = new BehaviorSubject({});
    this._configProducts = new BehaviorSubject(this.products);
    this._configProduct = new BehaviorSubject(this.Product);
    this._configRole = new BehaviorSubject(this.role);
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getProducts()]).then(() => {
        resolve();
      }, reject);
    });
  }

  setProducts(products: any) {
    this.products = products;
    this._configProducts.next(products);
  }

  setProduct(Product: any, isNotify: boolean) {
    this.Product = Product;
    if (isNotify) this._configProduct.next(Product);
  }

  /**
   * Get Products
   *
   * @returns {Promise<any>}
   */
  getProducts(showall?: boolean) {
    return this._httpClient.get(
      `${environment.apiBaseUrl}/rest/itemCategory?type=product&include=stats&draw=1&start=0&length=1000${
        showall ? "&isShowAll=true" : ""
      }`
    );
  }

  getCourses(showall?: boolean) {
    return this._httpClient.get(
      `${environment.apiBaseUrl}/snode/icategory?type=service&include=stats&draw=1&start=0&length=1000${
        showall ? "&isShowAll=true" : ""
      }`
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // REDO IN WISELY UI
  // TBD
  // -----------------------------------------------------------------------------------------------------
  newGetProductsList(types = "course,merchandise") {
    return this._httpClient.get(
      `${environment.apiBaseUrl}/snode/icategory/products?types=${types}`).pipe(map((products: any) => {
        return products?.reduce((acc: {courses: any[], merchandise: any[], bundles: any[] }, product: any) => {
          if(product.categoryType === 'SERVICE'){
            acc.courses.push(product);
          } else if(product.categoryType === 'PRODUCT'){
            acc.merchandise.push(product);
          }
          else if(product.categoryType === 'BUNDLE'){
            acc.bundles.push(product);
          }
          return acc
          }, {courses:[], merchandise:[], bundles:[]}
        )
      }
    ))
  }

  public createProductBundle(payload: any){
    return this._httpClient.post(`${environment.apiBaseUrl}/snode/pbundle`,payload);
  }

  public getProductBundleList(fetchOptions: any){
    var url = `${environment.apiBaseUrl}/snode/pbundle`
    url += `?start=${fetchOptions.start}`;
    url += `&max=${fetchOptions.max}`;
    url += `&isShowArchived=${fetchOptions.isShowArchived}`;
    url += fetchOptions?.search ? `&search=${fetchOptions.search}` : ``;
    return this._httpClient.get(url);
  }

  public updateProductBundle(payload: any){
    return this._httpClient.put(`${environment.apiBaseUrl}/snode/pbundle/${payload.guId}`,payload);
  }

  public getProductBundleDetails(guId) {
    return this._httpClient.get(`${environment.apiBaseUrl}/snode/pbundle/${guId}`);
  }

  getProductsObj() {
    return this._httpClient.get(`${environment.apiBaseUrl}/rest/itemCategory?type=product&include=stats&draw=1`).pipe(
      map((result: any) => {
        this.setProducts(result.data);
        return result;
      })
    );
  }

  getProductDetail(guid, isNotify) {
    return this._httpClient.get(`${environment.apiBaseUrl}/rest/itemCategory/${guid}?include=details,template`).pipe(
      map((result: any) => {
        this.setProduct(result, isNotify);
        return result;
      })
    );
  }

  get getProduct() {
    return this._configProduct.asObservable();
  }

  get getAllProducts() {
    return this._configProducts.asObservable();
  }

  getUntemplates() {
    return this._httpClient.get(`${environment.apiBaseUrl}/stemplate/untemplate?orgId=master&type=course`);
  }

  getUntemplatesByOrgId(orgId, start, max, type): Observable<any> {
    return this._httpClient.get(
      `${environment.apiBaseUrl}/stemplate/untemplate?orgId=${orgId}&type=${type}&offset=${start}&max=${max}`
    );
  }

  addUntemplate(form: any) {
    let url = `${environment.apiBaseUrl}/stemplate/untemplate`;
    return this._httpClient.post(url, form);
  }

  addUntemplateNew(form: string): Observable<any> {
    let url = `${environment.apiBaseUrl}/stemplate/untemplate`;
    return this._httpClient.post(url, form).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  deleteUntemplate(id: any): Observable<any> {
    return this._httpClient.delete(`${environment.apiBaseUrl}/stemplate/untemplate/${id}`);
  }

  addProduct(form: any) {
    return this._httpClient.post(`${environment.apiBaseUrl}/snode/icategory`, form);
  }
  updateProduct(form: any, guId: any) {
    return this._httpClient.put(`${environment.apiBaseUrl}/snode/icategory/${guId}`, form);
  }

  updateEmail(form: any, guId: any) {
    return this._httpClient.put(`${environment.apiBaseUrl}/snode/icategory/${guId}`, form);
  }

 
  // addUntemplateToProduct(form: any) {
  //   return this._httpClient.post(`${environment.apiBaseUrl}/unlayertemplate/categories`, form);
  // }
  // updateUntemplateToProduct(form: any, id: any) {
  //   return this._httpClient.put(`${environment.apiBaseUrl}/unlayertemplate/categories/${id}`, form);
  // }

  addUntemplateToProduct(form: any) {
    return this._httpClient.post(`${environment.apiBaseUrl}/stemplate/page`, form);
  }

  updateUntemplateToProduct(form: any, guId: any) {
    return this._httpClient.put(`${environment.apiBaseUrl}/stemplate/page/${guId}`, form);
  }

  addUntemplateToEvent(form: any) {
    return this._httpClient.post(`${environment.apiBaseUrl}/stemplate/program`, form);
  }

  updateUntemplateToEvent(form: any, guId: any) {
    return this._httpClient.put(`${environment.apiBaseUrl}/stemplate/program/${guId}`, form);
  }

  getProductsList() {
    return this._httpClient.get(`${environment.apiBaseUrl}/rest/itemCategory?type=product&include=stats&draw=1`).pipe(
      map((result: any) => {
        return result.data;
      })
    );
  }

  ProductDetail(guid) {
    return this._httpClient.get(`${environment.apiBaseUrl}/rest/itemCategory/${guid}?include=details,template`);
  }

  deleteProduct(guid) {
    return this._httpClient.delete(`${environment.apiBaseUrl}/rest/itemCategory/${guid}`);
  }

  getPricingDetail(guid, archiveChecked) {
    let archived = archiveChecked ? "include=disabled" : "isVisible=true";
    return this._httpClient.get(`${environment.apiBaseUrl}/rest/itemCategory/${guid}/items?${archived}`);
  }

  getSupplier() {
    return this._httpClient.get(`${environment.apiBaseUrl}/rest/itemSupplier`);
  }

  addPricing(body) {
    return this._httpClient.post(`${environment.apiBaseUrl}/rest/item`, body);
  }

  editPricing(guid, body) {
    return this._httpClient.put(`${environment.apiBaseUrl}/rest/item/${guid}`, body);
  }

  deletePricing(guid) {
    return this._httpClient.delete(`${environment.apiBaseUrl}/rest/item/${guid}`);
  }

  /*ProductPreview(guid, name) {
    return this._httpClient
      .get<any>(`${environment.apiBaseUrl}/unlayertemplate/categories?guid=${guid}&name=${name}`)
      .pipe(map(resp => resp.filter(x => x.unhtml != "")[0]));
  }*/

  ProductPreview(guId: string, name: string) {
    return this._httpClient.get(`${environment.apiBaseUrl}/stemplate/public/course/${guId}`, {
      withCredentials: false
    });
  }

  productPublish(guid, body) {
    return this._httpClient.put(`${environment.apiBaseUrl}/rest/itemCategory/${guid}`, body);
  }

  addSupplier(body) {
    return this._httpClient.post(`${environment.apiBaseUrl}/rest/itemSupplier`, body);
  }

  getcloudinaryBaseURL(scaling_modes: "fill" | "Fit" | "Limit" = "fill", height: number = 500, width: number = 750) {
    return this._appLoadService.onTenantConfigChanged.pipe(
      take(1),
      map((tenantConfig: any) => {
        return {
          cloudinaryBaseURL: `https://res.cloudinary.com/${tenantConfig.cloudName}/image/upload/c_${scaling_modes},h_${height},w_${width}/`,
          others: tenantConfig
        };
      })
    );
  }
}
